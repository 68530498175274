import React, { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import styles from './style.module.scss';
import AtriasError from '../../../assets/atrias-error.svg';
import MainContentLayout from '../BaseLayout/MainContentLayout/MainContentLayout';

const Error = (props: {
    title?: string;
    text: string;
    showInsideLayout?: boolean;
    errorMessage?: string;
}): ReactElement => {
  const content = (
    <Grid
      container
      direction="column"
      className={
                props.showInsideLayout
                  ? styles.errorContainerNoMargin
                  : styles.errorContainer
            }
      alignItems="center"
    >
      <Grid item className={styles.errorImage}>
        <img alt="Error" src={AtriasError} />
      </Grid>
      <Grid item className={styles.errorText}>
        <p>{props.text}</p>
      </Grid>
      {props.errorMessage ? (
        <Grid item className={styles.errorMessage} xs={9} md={7}>
          <p>{props.errorMessage}</p>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );

  return props.title && props.showInsideLayout ? (
    <MainContentLayout pageTitle={props.title}>{content}</MainContentLayout>
  ) : (
    content
  );
};

export default Error;
