import React from 'react';
import Role from './auth/Role';

const BatchPlanning = React.lazy(() => import('./pages/BatchPlanning/BatchPlanning'));
const DatabaseUpdate = React.lazy(() => import('./pages/DatabaseUpdate/DatabaseUpdate'));

export const HomePagePath = '/';
export const RedirectFromHomePagePath = '/planning';

export interface RouteTemplate {
    path: string;
    component?: React.LazyExoticComponent<() => React.ReactElement>;
    navigation: {
        title: string;
    },
    roles: Role[];
}

const RouteConfig: RouteTemplate[] = [
  {
    path: '/planning',
    component: BatchPlanning,
    navigation: {
      title: 'batch-treatment-navigation-title',
    },
    roles: [Role.User, Role.Admin],
  },
  {
    path: '/database',
    component: DatabaseUpdate,
    navigation: {
      title: 'Database update',
    },
    roles: [Role.Admin],
  },
];

export default RouteConfig;
