import {
  Grid, Link, Typography,
} from '@material-ui/core';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { AtriasLogo } from '@atrias/react-atrias-components';
import { useTranslation } from 'react-i18next';
import UserInfo from '../../../Auth/UserInfo/UserInfo';
import styles from './styles.module.scss';
import RouteConfig from '../../../../RouteConfig';

const SidebarContent = (): ReactElement => {
  const { t } = useTranslation('common');
  return (
    <Grid
      container
      className={styles.contentContainer}
      direction="column"
      justify="space-between"
      alignItems="center"
    >
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Link to="/" component={RouterLink}>
            <AtriasLogo className={styles.atriasLogo} />
          </Link>
        </Grid>
        <Grid item className={styles.applicationTitle}>
          <Typography
            variant="h2"
          >
            {t('application-name')}
          </Typography>
        </Grid>
        <Grid item className={styles.links} container direction="column">
          {
                RouteConfig.map((route) => (
                  <Grid item>
                    <Link
                      to={route.path}
                      className={styles.navLabel}
                      activeClassName={styles.activeNavLabel}
                      component={RouterNavLink}
                      color="inherit"
                    >
                      {t(route.navigation.title)}
                    </Link>
                  </Grid>
                ))
            }

        </Grid>
      </Grid>
      <Grid item className={styles.bottomItems} container direction="column">
        <UserInfo />
      </Grid>
    </Grid>
  );
};

export default SidebarContent;
