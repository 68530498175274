import { EnvironmentBanner } from '@atrias/react-atrias-components';
import {
  Drawer,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import SidebarContent from './SidebarContent/SidebarContent';
import styles from './style.module.scss';

const BaseLayout = (props: { children: ReactElement }) : ReactElement => (
  <>
    <Drawer
      className={styles.navDrawer}
      variant="persistent"
      anchor="left"
      open
      PaperProps={{ elevation: 11 }}
    >
      <SidebarContent />
    </Drawer>
    {
        window.runConfig.environment !== 'PRD'
          ? (
            <header className={styles.envHeader}>
              <EnvironmentBanner
                version={window.runConfig.version}
                environment={window.runConfig.environment}
                explanationText="This a test environment"
              />
            </header>

          )
          : null
      }
    <main className={styles.content}>
      {props.children}
    </main>
  </>
);

export default BaseLayout;
