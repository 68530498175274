import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: window.runConfig.adClientId,
    redirectUri: window.location.origin,
    authority: window.runConfig.adAuthority,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

export const loginRequest = {
  scopes: window.runConfig.userScopes.split(','),
};
