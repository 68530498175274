import React, { useContext } from 'react';
import {
  Grid, Typography, Tooltip,
} from '@material-ui/core';
import User from '../../../auth/User';
import UserContext from '../../../auth/UserContext';

import styles from './style.module.scss';

const UserInfo = (): React.ReactElement => {
  const currentUser: User | undefined = useContext(UserContext);

  return (
    <Grid
      container
      className={styles.userInfo}
      direction="row"
      wrap="nowrap"
      alignItems="center"
    >
      <Grid item container direction="column" zeroMinWidth wrap="nowrap">
        <Grid item zeroMinWidth>
          <Typography variant="body2" noWrap>
            <Tooltip title={currentUser?.name ?? ''} placement="right">
              <strong>{currentUser?.name}</strong>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
