import React, { Component, ErrorInfo, ReactNode } from 'react';

interface State {
    hasError: boolean;
    errorInfo?: ErrorInfo;
}

interface Props {
    children: ReactNode;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ errorInfo });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <code>{this.state.errorInfo}</code>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
