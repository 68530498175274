import React, { ReactElement, useContext } from 'react';
import { MsalContext } from '@azure/msal-react';
import User from './User';
import VolumiaClaims from './VolumiaClaims';
import Error from '../components/Layout/Error/Error';
import Role from './Role';

const UserContext = React.createContext<User | undefined>(undefined);

export const UserProvider = (props: {
    children: ReactElement;
}): ReactElement => {
  const { accounts } = useContext(MsalContext);

  if (accounts.length === 0) {
    // User provider should be placed within MsalProvider.
    // At least one account should always be signed in at this point.
    return <Error text="Oh no! Something went wrong during authentication." />;
  }

  const users: User[] = accounts.map((account) => {
    const userRoles = (account.idTokenClaims as VolumiaClaims)?.roles?.map((role: string) => role as Role) ?? [];

    return new User(account.name ?? '', userRoles);
  });

  // we assume there is always only one user
  return (
    <UserContext.Provider value={users[0]}>
      {props.children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;
