import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import RouteConfig from './RouteConfig';
import BaseLayout from './components/Layout/BaseLayout/BaseLayout';
import RoleGuardedRoute from './components/Auth/RoleGaurdedRoute/RoleGuardedRoute';

const App: React.FC = () => {
  const routes = RouteConfig.map((route) => (
    <RoleGuardedRoute roles={route.roles} path={route.path} component={route.component} />
  ));

  return (
    <>
      <Helmet>
        <title>Volumia | Atrias</title>
        <meta
          name="description"
          content="Volumia"
        />
      </Helmet>
      <BrowserRouter>
        <BaseLayout>
          <Suspense fallback={<p>Loading</p>}>
            <Toaster position="top-center" />
            <Switch>
              <Route exact path="/">
                <Redirect to="/planning" />
              </Route>
              {routes}
            </Switch>
          </Suspense>
        </BaseLayout>
      </BrowserRouter>
    </>

  );
};

export default App;
