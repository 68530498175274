import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';

export const MsalInstance = new PublicClientApplication(msalConfig);

export default {
  async getAccessToken(scopes: Array<string>): Promise<string | null> {
    const accounts = MsalInstance.getAllAccounts();

    if (accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      return null;
    }

    const request = {
      scopes,
      account: accounts[0],
    };

    const authResult = await MsalInstance.acquireTokenSilent(request);
    return authResult?.accessToken;
  },
};
