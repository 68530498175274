import React, { ReactElement } from 'react';
import { Typography, Grid, Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Skeleton } from '@material-ui/lab';

import styles from './style.module.scss';

const MainContentLayout = (props: {
    pageTitle: string;
    topRightElements?: ReactElement;
    children?: ReactElement;
    backLinkUrl?: string;
    backLinkTitle?: string;
    loading?: boolean;
}): ReactElement => (
  <>
    <Helmet>
      <title>
        {props.pageTitle}
        {' '}
        | Atrias
      </title>
    </Helmet>
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      wrap="nowrap"
      className={styles.layoutHeader}
    >
      <Grid item container>
        {!props.loading ? (
          <Grid container direction="column">
            {props.backLinkUrl && props.backLinkTitle ? (
              <Grid item>
                <Link to={props.backLinkUrl} component={RouterLink}>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="flex-end"
                    wrap="nowrap"
                  >
                    <Grid item className={styles.backIcon}>
                      <ArrowBackRoundedIcon fontSize="small" />
                    </Grid>
                    <Grid item>{props.backLinkTitle}</Grid>
                  </Grid>
                </Link>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item>
              <Typography variant="h1">{props.pageTitle}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Skeleton
            animation="wave"
            variant="rect"
            className={styles.layoutTitleSkeleton}
          />
        )}
      </Grid>
      <Grid item className={styles.topRightElements}>
        {props.topRightElements ?? <></>}
      </Grid>
    </Grid>
    {props.children ? props.children : null}
  </>
);

export default MainContentLayout;
