import Role from './Role';

class User {
    name: string;

    roles: Role[];

    constructor(name: string, roles: Role[]) {
      this.name = name;
      this.roles = roles;
    }

    isAuthorizedFor = (roles: Role[]): boolean => this.roles.some((userRole) => roles.indexOf(userRole) !== -1);
}

export default User;
