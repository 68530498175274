import React, { ReactElement, useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import UserContext from '../../../auth/UserContext';
import User from '../../../auth/User';
import Role from '../../../auth/Role';
import Error from '../../Layout/Error/Error';

interface RoleBasedRouteProps extends RouteProps {
    roles: Role[];

}

const RoleGuardedRoute = ({
  component: Component,
  roles,
  ...rest
}: RoleBasedRouteProps): ReactElement => {
  const currentUser: User | undefined = useContext(UserContext);

  if (!Component) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!roles || !roles.length) {
          return (<Component {...props} />);
        }

        if (currentUser?.isAuthorizedFor(roles)) {
          return (<Component {...props} />);
        }

        return <Error showInsideLayout text="You do not have access to this page." title="No Access" />;
      }}
    />
  );
};

export default RoleGuardedRoute;
