import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { ThemeProvider } from '@material-ui/core/styles';
import { AtriasTheme, PageLoader } from '@atrias/react-atrias-components';
import { MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import '@atrias/react-atrias-components/dist/index.css';
import { InteractionType } from '@azure/msal-browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Config } from './utils/CustomWindow';
import { MsalInstance } from './auth/MsalUtils';
import Error from './components/Layout/Error/Error';
import { UserProvider } from './auth/UserContext';
import { initI18n } from './i18n';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { loginRequest } from './auth/msalConfig';

declare global {
  interface Window {
    runConfig: Config;
  }
}

initI18n();

const PageLoaderContainer = (): React.ReactElement => (
  <div style={{ height: '100vh' }}>
    <PageLoader />
  </div>
);

const AuthErrorComponent = (error: MsalAuthenticationResult): React.ReactElement => (
  <Error
    text={
        error.error?.errorMessage
          ? 'An error occured during authentication. Please try again later.'
          : 'An error occured during authentication.'
      }
    errorMessage={error.error?.errorMessage}
  />
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={PageLoaderContainer}>
      <ThemeProvider theme={AtriasTheme}>
        <MsalProvider instance={MsalInstance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            loadingComponent={PageLoaderContainer}
            errorComponent={AuthErrorComponent}
          >

            <UserProvider>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>

            </UserProvider>

          </MsalAuthenticationTemplate>

        </MsalProvider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
